import { utils } from 'xlsx';
import * as XLSX from 'xlsx';
import { generateBlUniqId } from '~/utils/common.utils';
import { getCustomFields, UploadTypesEnum } from '~/utils/file.util';

export interface ContainerUpload {
  bl_no?: string
  cntr_no?: string
  carrier_no?: string
  doc_type?: string
  custom_fields?: Record<string, any>
}

export const useBulkUploadStore = defineStore('bulkUploadStore', {
  state: (): any => ({
    fileName: '',
    workbook: undefined,
    worksheet: undefined,
    parsedJson: undefined,
    mappedMainFields: {},
    mappedCustomFields: {},
    headerRowIndex: 0,
    isMappingsValid: false,
    uploadType: UploadTypesEnum.CONTAINER,
    disableConfirmButton: false,
    mappingErrors: {},
    validatedBulkUploadPayload: [],
  }),
  actions: {
    setMappingsValid(flag: boolean) {
      this.isMappingsValid = flag;
    },
    setUploadType(type: string) {
      this.uploadType = type;
    },
    setFileData({ fileBuffer, fileName }: any) {
      this.mappedMainFields = {};
      this.mappedCustomFields = {};
      this.headerRowIndex = 0;
      this.isMappingsValid = false;
      this.uploadType = UploadTypesEnum.CONTAINER;

      const workbook: any = XLSX.read(fileBuffer);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const parsedJson: any = utils.sheet_to_json(worksheet, { range: 0 });

      this.worksheet = worksheet;
      this.workbook = workbook;
      this.fileName = fileName;
      this.parsedJson = parsedJson;
    },
    setConfirmButtonToDisable(status: boolean) {
      this.disableConfirmButton = status;
    },
    getAggregatedUploadList(): any {
      const mailFieldsMappings: any = this.mappedMainFields;
      const customFieldsMappings: any = this.mappedCustomFields;
      const uploadType: any = this.uploadType;
      const orgStore = useOrgStore();
      const orgCustomFields: any = orgStore.activeCustomFields;
      const uploadList: any = {};

      const headerRowIndex = this.headerRowIndex;
      const worksheet: any = this.worksheet;
      const parsedJson: any = utils.sheet_to_json(worksheet, { range: headerRowIndex });

      parsedJson.forEach((row: any) => {
        const uploadRow: any = {
          cntr_no: row[mailFieldsMappings.cntr_no],
          bl_no: row[mailFieldsMappings.bl_no],
          carrier_no: row[mailFieldsMappings.carrier_no] || 'AUTO',
        };
        const uniqUploadId = generateBlUniqId(uploadRow);
        uploadRow.uniqUploadId = uniqUploadId;
        const isValidRow = uploadType === UploadTypesEnum.BL ? uploadRow.bl_no : uploadRow.cntr_no;
        if (isValidRow) {
          const customFields = getCustomFields(row, customFieldsMappings, orgCustomFields);
          if (uploadList[uniqUploadId]) {
            uploadList[uniqUploadId].customFields.push(customFields);
          }
          else {
            uploadList[uniqUploadId] = { uploadItem: uploadRow, customFields: [customFields] };
          }
        }
      });
      return uploadList;
    },
    async addBookmarkCustomField(payload: any) {
      const augmentationTypeId = payload?.custom_field_id;
      const user_data = payload.user_data;
      if (!user_data) {
        return Promise.resolve(true);
      }
      const payloadData: any = {
        user_data,
      };
      if (payload?.isSubField) {
        payloadData.belongs_to = payload.belongs_to;
      }

      const runtimeConfig = useRuntimeConfig();
      const userStore = useUserStore();
      const orgId = userStore.activeOrgId;
      const { $ofetch }: any = useNuxtApp();
      const url = `${runtimeConfig.public.API_URL2}orgs/${orgId}/eta/tracking/bill-of-lading-bookmarks/${payload.bookmark_id}/augmentations/${augmentationTypeId}`;
      return $ofetch(url, {
        method: 'post',
        body: payloadData,
      });
    },
    setHeaderRowIndex(index: number) {
      this.headerRowIndex = index;
      this.isMappingsValid = false;
      this.mappedMainFields = {};
      this.mappedCustomFields = {};
    },
    setMappedFields({ mappedMainFields, mappedCustomFields }: any) {
      this.mappedMainFields = mappedMainFields;
      this.mappedCustomFields = mappedCustomFields;
      if (this.uploadType === UploadTypesEnum.CONTAINER) {
        this.isMappingsValid = !!mappedMainFields.cntr_no;
      }
      else {
        this.isMappingsValid = !!mappedMainFields.bl_no;
      }
    },
    getMainUploadList() {
      const mailFieldsMappings: any = this.mappedMainFields;
      const customFieldsMappings: any = this.mappedCustomFields;
      const orgStore = useOrgStore();
      const orgCustomFields: any = orgStore.activeCustomFields;
      const uploadList: any = [];

      const headerRowIndex = this.headerRowIndex;
      const worksheet: any = this.worksheet;
      const parsedJson: any = utils.sheet_to_json(worksheet, { range: headerRowIndex });
      parsedJson.forEach((row: any) => {
        const uploadRow: any = {
          cntr_no: row[mailFieldsMappings.cntr_no],
          bl_no: row[mailFieldsMappings.bl_no],
          carrier_no: row[mailFieldsMappings.carrier_no] || 'AUTO',
          customFields: getCustomFields(row, customFieldsMappings, orgCustomFields),
        };
        if (this.uploadType === UploadTypesEnum.BL) {
          uploadRow.bl_no && uploadList.push(uploadRow);
        }
        else {
          uploadRow.cntr_no && uploadList.push(uploadRow);
        }
      });
      return uploadList;
    },
    setMappingErrors(mapping: any) {
      this.mappingErrors = mapping;
    },
    setValidatedBulkUploadPayload(payload: any) {
      this.validatedBulkUploadPayload = payload;
    },
  },
});
